<template>
  <div id="app">
    <!-- <button @click="handleOpenDrawer">打开图片</button>
    <ImageDrawer ref="refImageDrawer" @onSave="handleSave" /> -->
    <ArticleDownload></ArticleDownload>
    <!-- <MainIndex></MainIndex> -->
  </div>
</template>

<script>
// import ImageDrawer from './components/index.vue';
// import MainIndex from '@/components/FabricSale.vue';
import MainIndex from '@/components/VipDown.vue';
// import MyFframe from '@/components/iframe.vue';
import ArticleDownload from '@/components/ArticleDownload.vue';
// import MainIndex from '@/components/AilabelTest.vue';
// import MainIndex from '@/components/ImgMove.vue';

export default {
  name: 'App',
  // components: { ImageDrawer },
  components: { ArticleDownload },
  // components: { MainIndex },
  methods: {
    // handleOpenDrawer() {
    //   this.$refs.refImageDrawer.openUrl('./img.jpeg');
    // },

    // handleSave(base64, file) {
    //   console.log(base64);
    //   console.log(file);
    // }
  }
};
</script>

<style>
/* #app{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
} */
</style>
